import React from 'react';
import Lab from './lab';
import Lesson from './lesson';
import Quiz from './quiz';

function Learning(props: {}){
    return(
        // <div>learning WIP</div>
        <Lesson />
    );
}

export default Learning;