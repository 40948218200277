import React from 'react';
import Lesson from './lesson';


function LessonContainer(props: {}) {

   
    return (
        <Lesson />
    );
}

export default LessonContainer;