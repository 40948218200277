import React from 'react';
import Page from '../../components/page';

interface GradesProps {

}

function Grades(props: GradesProps){
    return(
        <Page title='Grades'>
            Grades!
        </Page>
    );
}

export default Grades;